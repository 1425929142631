.products-text{
    margin-top: 50px;
    font-size: 3.5vw;
    margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
    .products-text{
        font-size: 40px;
    }
}