.contact {
    margin-top: 50px;
}

.contact-title {
    font-size: 30px;
    font-weight: 500;
}

.contact-tiles {
    text-align: center;
    display: flex;
    justify-content: space-around;
    margin-bottom: -10px;
    padding-bottom: 20px;
}

.contact-tile-title {
    font-size: 20px;
    font-weight: 900;
}

.contact-tile-name {
    color: #9c8b7e;
    text-decoration: underline;
    font-size: 20px;
    cursor: pointer;
}

.contact-maps {
    width: 400px;
    height: 200px;
}

@media screen and (max-width : 768px) {
    .contact-tiles {
        flex-direction: column;
        align-items: center;
    }
    .contact-maps {
        width: 100%;
    }
}