.main-home{
    padding: 0px 50px;
}
.home-img {
    width: 100%;
    margin: auto;
    position: relative;
    text-align: center;
}

.text-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    font-size: 4.3vw;
}

.main-img-align {
    width: 100%;
}

.quality-box{
    width: 100%;
    padding: 0px 5px;
}


.quality-boximg{
    width: 100%;
    height: 25vw;
}

.quality-text{
    margin-top: 120px;
    font-size: 3.5vw;
    margin-bottom: 30px;
}

.quality-title{
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #48403d;
}

.quality-description{
    text-align: center;
    line-height: 1.5;
    font-size: 20px;
    font-weight: 500;
    margin-top: 5px;
    color: #48403d;
}

.sliding-images {
    padding: 0px 20px
}

.carousel .thumbs-wrapper {
    display: none;
}

.carousel .control-dots {
    display: none;
}

.carousel-img {
    height: 520px;
    margin-top: 50px;
}

.carousel .slide .legend {
    transition: all .5s ease-in-out;
    position: absolute;
    bottom: 63px;
    border-radius: 10px;
    background: transparent !important;
    color: black;
    font-weight: 700;
    padding: 10px;
    font-size: 46px;
    text-align: center;
    opacity: 1;
    transition: opacity .35s ease-in-out;
    top: 310px;
    left: 20px;
    margin-left: 0px !important;
    width: auto !important;
}

.paragraph {
    padding: 0px 20px;
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
}

.gallery-text {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
}

.gallery-imgs {
    display: flex;
    justify-content: space-between;
}

.disp-img {
    width: 500px;
    height: 500px;
}

.our-images {
    padding: 0px 20px;
}

.left-para,
.right-para {
    width: 50%;
}

.left-para,
.right-para {
    width: 400px;

}

.img-para {
    width: 250px;
    display: flex;
    margin: auto;
}


.homepage-contact {
    padding: 0px 20px;
}

.quality-group-boxes{
    display: flex;
}

@media screen and (max-width:768px) {
    .main-home{
        margin-top: 40px;
        padding: 0px 20px;
    }
    .quality-text{
        margin-top: 80px;
        font-size: 40px;
    }
    .quality-group-boxes{
        display: block;
    }
    .main-img-align{
        height: 600px;
    }
    .text-centered{
        font-size: 40px;
    }
    .quality-boximg{
        height: auto;
    }
    .quality-box{
        margin-top: 10px;
        margin-bottom: 100px;
    }
}