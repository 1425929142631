.machinery-boximg {
    width: 100%;
    height: 35vw;
}

.machinery-box {
    width: 100%;
    padding: 0px 1.5vw;
}

.machinery-imgs {
    display: flex;
    padding: 0px 4vw
}

@media screen and (max-width : 768px) {
    .machinery-boximg {
        height: 300px;
    }

    .machinery-box {
        width: 100%;
        padding: 0px;
    }
    .machinery-imgs {
        display: block;
        padding: 0px;
    }
}