.about-img {
    width: 100%;
    margin: auto;
    position: relative;
    text-align: center;
}


.blurred-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(4px);
    -webkit-filter: blur(4px);
    /* For Safari */
    z-index: 1;
}

.content {
    position: relative;
    z-index: 2;
    color: white;
    text-align: center;
    padding: 20px;
    height: 550px;
    font-size: 4.3vw;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.about-viewbtn {
    background: white;
    border-radius: 60px;
    color: black;
    text-align: center;
    margin: auto;
    width: 200px;
    font-size: 20px;
    padding: 25px;
}

.about-img-title {
    margin: 0px 0px 50px 0px;
}

.about-data {
    display: flex;
    align-items: center;
}

.about-para-title {
    font-size: 40px;
    font-weight: 500;
}


.about-para-text {
    font-size: 25px;
    font-weight: 500;
    max-width: 800px;
}

.about-para-data {
    padding: 0px 20px 0px 50px;
    line-height: 2;
}

.about-para-data,
.about-para-data-img {
    width: 50%;
}

.about-para-img {
    width: 100%;
}
.contact-display{
    padding-left: 50px;
}

@media screen and (max-width: 768px) {
    

    .about-img {
        margin-top: 20px;
    }

    .about-data {
        flex-direction: column;
        align-items: start;
    }

    .about-para-data,
    .about-para-data-img {
        width: 100%;
    }
    .about-para-data{
        padding: 0px 20px;
        width: 90%;
    }
    .about-para-img{
        padding: 0px 20px;
        width: 90%;
        margin-top: 20px;
    }
    .about-para-text{
        font-size: 20px;
        line-height: 1.7;
    }
    .contact-display{
        padding: 0px 20px;
    }
}