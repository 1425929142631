.nav {
    align-items: center;
    z-index: 5;
    position: relative;
    width: 100%;
}

.nav-left-title{
    align-items: center;
    display: flex;
}

.group-bars {
    display: none;
}

.mainlogo-img{
    width: 40px;
    height: 55px;
    margin-right: 10px;
}

.nav-title {
    padding-top: 30px;
    text-align: center;
    font-size: 31px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    font-size: 20px;
    cursor: pointer;
}

.nav-content {
    text-align: center;
    display: flex;
    justify-content: center;
}

.nav-button {
    cursor: pointer;
    padding: 0px 20px;
    font-size: 20px;
}

.nav-button:hover {
    text-decoration: underline;
}

.mainimg-width {
    width: 65px;
    margin-top: 5px;
}


@media screen and (max-width:768px) {
    .nav-title {
        text-align: left;
        display: flex;
        font-size: 15px;
        margin-left: 20px;
        justify-content: space-between;
        align-items: center;
    }

    .group-bars {
        cursor: pointer;
        display: block;
    }

    .hr-bars {
        width: 35px;
        border: 1px solid;
        margin-right: 20px;
    }
    .nav-content{
        display: none;
    }

    .nav-mobile-content {
        position: fixed;
        height: 100%;
        width: 100%;
        z-index: 3;
        top: 0;
        background: #D5D2CD;
    }
    .nav-mobile-title{
        display: flex;
        font-size: 25px;
        margin-top: 30px;
        margin-left: 20px;
        margin-right: 20px;
        justify-content: space-between;
    }

    .nav-mobile-navs{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin-top: -55px
    }
    .nav-mobile-button{
        font-size: 45px;
        margin: 10px;
    }
}