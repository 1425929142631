.contact-form {
    display: flex;
    padding: 0px 50px;
}

.contact-text {
    font-size: 70px;
    font-weight: 500;
    color: #352D28;
}

.contact-title {
    margin-top: 10px;
    font-size: 20px;
    line-height: 2;
    color: #352D28;
    max-width: 550px;
}

.contact-address {
    margin-top: 10px;
    color: #352D28;
}

.contact-email {
    margin-top: 10px;
    color: #352D28;
}

.contact-phone {
    margin-top: 10px;
    color: #352D28;
}

.reach-color {
    color: #352D28;
    text-decoration: underline;
    cursor: pointer;
    opacity: 0.7;
    font-size: 18px;
}

.contact-text-left,
.contact-img-right {
    width: 50%;
}

.contactpage-img {
    width: 100%;
}



.textfield {
    width: 100%;
    background: transparent;
    border: 1px solid;
    border-radius: 50px;
    padding: 20px;
    max-width: 240px;
    margin-top: 10px;
}

.textfieldemail {
    width: 100%;
    background: transparent;
    border: 1px solid;
    border-radius: 30px;
    padding: 20px;
    max-width: 550px;
    margin-top: 10px;
}

.contact-sendbtn {
    background: #9C8B7E;
    padding: 20px 40px;
    color: white;
    width: 40px;
    border-radius: 50px;
    font-size: 20px;
    margin-top: 20px;
    cursor: pointer;
}

.textfieldemail:hover,
.textfield:hover {
    background: white;
}

.contact-details {
    display: flex;
    max-width: 600px;
}

@media screen and (max-width: 768px) {
    .contact-form {
        display: block;
        padding: 0px 20px;
    }

    .contact-text-left,
    .contact-img-right {
        width: 100%;
    }

    .contact-text {
        font-size: 40px;
    }

    .contact-details {
        display: block;
    }

    .textfield,
    .textfieldemail {
        width: 90%;
        max-width: 100%;
    }
}